.pagination {
  background: #9F9F9F;

  td {
	border-radius: 5px;
	padding: 5px 30px;
  }

  &__controls {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;

	&--left {
	  font-family: 'Roboto', sans-serif;
	  font-size: 12px;
	  line-height: 12px;
	  color: #FFFFFF;
	}

	&--right {
	  ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;

		li {
		  display: flex;
		  align-items: center;
		  margin-right: 10px;

		  button {
			background-color: transparent;
			border: none;
			height: 18px;
			color: #FFFFFF;
			font-family: 'Roboto', sans-serif;
			font-weight: 500;
			font-size: 10px;
			line-height: 18px;
			padding: 0;
			margin: 0;
			cursor: pointer;

			svg {
			  height: 18px;
			}

			&.number {
			  width: 18px;
			  text-align: center;
			  border-radius: 50%;
			  padding-top: 1px;

			  &.active {
				background-color: #FFFFFF;
				color: #9F9F9F;
			  }
			}

			&.disabled {
			  pointer-events: none;
			  opacity: .6;
			}
		  }

		  &.dots {
			color: #FFFFFF;
		  }
		}
	  }
	}
  }
}
