.codes-upload {
  display: block;

  &__label {
    margin-top: 12px;
    margin-right: 26px;
    color: #9F9F9F;
  }

  &__top {
    width: 100%;
    display: flex;
    justify-content: space-between;



    .codes-dropdown {
      width: 45%;
      display: flex;
      justify-content: flex-end;

      .dropdown {
        width: 239px;
      }
    }
  }

  &__bottom {
    width: 88%;
    padding: 42px 0;
    display: flex;
    justify-content: flex-start;
    gap: 30px;

    .codes-expiration {
      display: flex;

      svg {
        margin-right: 10px;
      }

      .react-datepicker-wrapper {
        width: auto;

        input {
          background: #FFFFFF;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          height: 40px;
          font-size: 14px;
          line-height: 1;
          color: rgba(0, 0, 0, 0.5);
          padding: 12px 15px;
          font-family: "Roboto", sans-serif;
          width: 111px;

          &:focus,
          &:focus-visible {
            outline: 1px solid #88c559;
          }
        }
      }

      .react-datepicker__day {
        &--keyboard-selected {
          background-color: #88c559;
        }

        &--selected {
          background-color: #88c559;
        }
      }
    }

    .codes-prize-validity {
      display: flex;

      input {
        width: 114px;
      }
    }

    .codes-validity {
      display: flex;

      input {
        width: 114px;
      }
      span {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        line-height: 1.6;
        margin: 8px 0 0 22px;
        color: #9F9F9F;
      }
    }
  }
}
