.search {
  position: relative;
  display: inline-block;

  input {
	width: 366px;
	height: 40px;
	background: #FFFFFF;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	padding: 13px 16px;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	line-height: 16px;
	color: rgba(0, 0, 0, 0.5);

	&::placeholder {
	  font-family: 'Roboto', sans-serif;
	  font-size: 14px;
	  line-height: 16px;
	  color: rgba(0, 0, 0, 0.3);
	}

	&:focus,
	&:focus-visible {
	  outline: 1px solid #88c559;
	}
  }

  svg {
	position: absolute;
	right: 12px;
	top: 12px;
	width: 16px;
	height: 16px;
	cursor: pointer;
	path {
	  fill: #000000;
	  fill-opacity: .5;
	}
  }
}
