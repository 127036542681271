.add-pools-container {
  height: 530px;

  .add-pools-header {
    display: flex;
    margin-bottom: 13px;
    width: 480px;
    justify-content: space-between;
  }

  .in-pools-list {
    width: 1000px;

    &__header {
      background: #9F9F9F;
      margin-bottom: 4px;
      border-radius: 5px;
      display: flex;

      th {
        width: 25%;
        color: #FFFFFF;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        padding: 20px 22px;
        white-space: nowrap;
      }
    }

    &__body {
      max-height: 457px;
      overflow: auto;
      margin-bottom: 30px;

      &::-webkit-scrollbar-thumb {
        background-color: #88C559; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        border: 3px solid #88C559; /* creates padding around scroll thumb */
      }

      &::-webkit-scrollbar-track {
        background: rgba(136, 197, 89, 0.2);
      }

      &::-webkit-scrollbar {
        width: 6px; /* width of the entire scrollbar */
      }
    }

    &__row {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #D9D9D9;
    }

    &__data {
      width: 25%;
      padding: 10px 22px;
      font-size: 14px;
      color: #000000;
      cursor: default;

      &.value {
        opacity: 0.5;
      }
    }
  }
}
