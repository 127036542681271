.timeline {
  width: 100%;
  padding-bottom: 20px;
  overflow-x: auto;

  table {
	border-collapse: collapse;
  }

  thead {
	th {
	  font-family: 'Roboto', sans-serif;
	  font-weight: 500;
	  font-size: 12px;
	  line-height: 14px;
	  color: rgba(0, 0, 0, 0.5);
	  border-bottom: 1px solid #EBEDF4;
	  padding: 14px 20px;
	  background: rgba(217, 217, 217, 0.2);
	  text-align: center;

	}
  }

  tbody {
	tr {

	  td {
		font-family: 'Roboto', sans-serif;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.2px;
		color: rgba(0, 0, 0, 0.5);
		text-align: center;
		padding: 14px 20px;
		min-width: 100px;

		input {
		  height: 30px;
		}

		.double-input-wrapper {
		  height: 30px;
		  width: 100px;

		  span {
			height: 30px;
		  }
		}

		svg {
		  width: 18px;
		  height: 16px;
		  fill: rgba(0, 0, 0, 0.5);
		  display: inline-block;

		  path {
			stroke: rgba(0, 0, 0, 0.5);
		  }
		}

		&.clickable {
		  cursor: pointer;
		}

		&.with-input {
		  .input {
			width: 60px;
		  }
		}
	  }
	}
  }
}
