.prize-pools {
  padding-top: 30px;
  padding-bottom: 30px;

  &__header {
    display: flex;
    margin-bottom: 10px;
    justify-content: flex-end;
    align-items: center;

    &--dropdown {
      width: 240px;
    }
  }

  &__body {
    min-height: 290px;

    a {
      display: block;
      margin-top: 26px;
      margin-bottom: 30px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      text-decoration: none;
      color: #88C559;
    }
  }
}
