.inside-pool {
  padding-top: 40px;

  &__header {
	display: flex;
	margin-bottom: 10px;
	align-items: center;
	justify-content: flex-end;
  }

  &__body {
    min-height: 288px;
  }
}
