.limits {
  padding-top: 40px;

  .error-message {
	position: relative;
	top: -30px;
  }

  &__duration {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 200px;
	margin-bottom: 20px;

	span {
	  font-family: 'Roboto', sans-serif;
	  font-size: 16px;
	  line-height: 1.6;
	  color: rgba(0, 0, 0, 0.5);
	}

	input {
	  width: 60px;
	}
  }

  &__type {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 496px;
	margin-bottom: 20px;

	input {
	  width: 80px;
	}

	.dropdown {
	  width: 400px;
	}
  }

  &__probability {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 750px;
	margin-bottom: 20px;

	span {
	  font-family: 'Roboto', sans-serif;
	  font-size: 16px;
	  line-height: 1.6;
	  color: rgba(0, 0, 0, 0.5);
	}

	.dropdown {
	  width: 400px;
	}

	input {
	  width: 64px;

	  &:last-child {
		width: 84px;
	  }
	}
  }
}
