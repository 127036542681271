.pool-details {
  padding-top: 60px;
  display: flex;
  min-height: 378px;

  &__left {
    width: 20%;
    margin-right: 2%;
  }

  &__right {
    width: 78%;

    &--input {
      margin-bottom: 20px;

      &:first-child {
        display: flex;

        .input-group:first-child {
          width: 138px;
          margin-right: 20px;
        }

        .input-group:last-child {
          width: 100%;
          margin-right: 20px;
        }
      }
    }

    &--bottom {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 40px;
    }

    &--bottom-input {
      &:first-child {
        width: 240px;
      }

      &:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: center;

        input {
          width: 120px;
          margin-right: 20px;
          margin-left: 20px;
        }

        span {
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          line-height: 1.6;
          color: rgba(0, 0, 0, 0.5)
        }
      }

      &:last-child {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          line-height: 1.6;
          color: rgba(0, 0, 0, 0.5);
          display: inline-block;
          margin-right: 20px;
        }
      }
    }
  }


}
