.winner-search {
  padding-top: 60px;
  padding-bottom: 60px;

  h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.6;
    color: #9F9F9F;
  }

  &__header {
    width: 100%;
    height: 56px;
    background: #F5F5F5;
    border-radius: 5px;
    padding: 8px 32px;
    margin-bottom: 10px;
  }
}
