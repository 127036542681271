.upload {
  background: #F5F5F5;
  border-radius: 5px;
  padding: 4px;
  cursor: pointer;

  input {
	display: none;
  }

  &__icon {
	background: #F0F0F3;
	box-shadow: inset -1px -1px 1px rgba(255, 255, 255, 0.7), inset 1px 1px 2px rgba(174, 174, 192, 0.2);
	border-radius: 5px;
	padding-top: 14px;
	padding-bottom: 14px;
	text-align: center;

	svg {
	  height: 100px;
	}
  }

  &__img {
	object-fit: cover;
	overflow: hidden;
	height: 132px;
	text-align: center;
	background: #F0F0F3;

	img {
	  border-radius: 5px;
	  max-width: 100%;
	  max-height: 100%;
	  display: inline-block;

	}
  }

  &__text {
	text-align: center;
	padding-top: 15px;
	padding-bottom: 15px;

	span {
	  font-family: 'Raleway', sans-serif;
	  font-weight: 400;
	  font-size: 14px;
	  line-height: 16px;
	  text-align: center;
	  color: #88C559;
	  display: inline-block;
	  width: 80%;
	  overflow-x: hidden;
	  text-overflow: ellipsis;
	}
  }

  .resultsImage {
    svg {
	  margin-top: 50px;
	  height: 97px;
    }
  }

  .gameImage {
    svg {
	  height: 75px;
	  margin-top: 16px;
	}
  }
}



