.modal {
  position: relative;
  z-index: 50;

  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .8);
  }

  &__container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
  }

  &__panel {
    width: 1060px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 30px 30px 0;

    h2 {
      margin: 0;
      padding-bottom: 20px;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.6;
      text-transform: uppercase;
      color: #9F9F9F;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      position: relative;
    }
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }

  &__description {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__footer {
    display: flex;
    padding-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    justify-content: space-between;

    &--link {
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 40px;
      text-transform: uppercase;
      color: #88C559;

      svg {
        margin-left: 24px;
        position: relative;
        top: 2px;
      }
    }

    &--totals {
      display: flex;
      margin-left: 37px;
      font-size: 12px;
      font-weight: 600;
      font-family: "Raleway", sans-serif;
      color: rgba(0, 0, 0, 0.5);

      div {
        width: 100px;
        padding-top: 5px;
        display: flex;
        flex-direction: column;
      }

      .total-value {
        margin-top: 5px;

        &.redeemed, &.reserved {
          color: #FF5F6D;
        }

        &.available {
          color: #444BFF;
        }
      }
    }

    &--btns {
      margin-left: auto;
      display: flex;
    }
  }
}

/* Custom modal styles */

.barcode-modal {
  .modal__backdrop {
    background: none;
  }

  .modal__panel {
    width: 600px !important;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }
}

.codes-list-modal, .codes-edit-modal {
  .modal__backdrop {
    background-color: rgba(0, 0, 0, .4);
  }

  .modal__panel {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }
}

.new-win-modal {
  .modal__panel {
    width: 480px!important;
  }

  &__input {
    display: flex;
    align-items: center;
    margin-bottom: 38px;
  }

  p {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5);
    margin: 0;
  }

  &__label {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6;
    color: rgba(0, 0, 0, 0.5);
    display: block;
    flex-basis: 110px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  textarea {
    height: 160px;
  }

  .dropdown__menu {
    height: 260px;
    overflow-y: auto;
  }
}

.import-prizes-modal {
  .modal__panel {
    width: 580px!important;
  }
}
