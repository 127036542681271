.btn {
  padding: 2px 22px 0;
  height: 40px;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  box-sizing: border-box;
  box-shadow: none;
  border-style: none;
  cursor: pointer;


  &.primary {
	background-color: #88C559;
	color: #FFFFFF;
	border-color: #88c559;
  }

  &.secondary {
    background-color: transparent;
    border-color: transparent;
    color: #88C559;
  }

  &.danger {
    background-color: #FF5F6D;
    color: #FFFFFF;
    border-color: #FF5F6D;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &.btn-table {
    height: 36px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

/* Button loader
--------------------------------- */
.small-spinner {
  animation: rotate 2s linear infinite;
  width: 20px;
  height: 20px;

  & .path {
    stroke: #FFFFFF;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
