.btn-table--resend {
    width: 7.5rem;
    max-width: 7.5rem;
}

.modal__no-prizes-available {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6;
    color: rgba(0, 0, 0, 0.5);
}