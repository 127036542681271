
.switch {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 18px;
  height: 20px;
  width: 36px;
  outline: none;
  border: 2px solid transparent;
  transition: all .2s ease-in-out;
  padding: 0;

  &.active {
	background-color: #88C559;
  }

  &.inactive {
	background-color: #C3CBCD;
  }


  &__circle {
	display: inline-block;
	pointer-events: none;
	width: 16px;
	height: 16px;
	background: #FFFFFF;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
	border-radius: 50%;
	transition: all .2s ease-in-out;

	&.active {
	  transform: translateX(16px);
	}

	&.inactive {
	  transform: none;
	}
  }
}
