.input {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 40px;
  font-size: 14px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.5);
  padding: 12px 22px;
  font-family: "Roboto", sans-serif;
  width: 100%;


  &[type=number] {
	padding-right: 4px;
	padding-left: 10px;
  }


  &::placeholder {
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	line-height: 14px;
	color: rgba(55, 63, 65, 0.3);
  }

  &:focus,
  &:focus-visible {
	outline: 1px solid #88c559;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.error {
  border: 1px solid #FF5F6D;
}
