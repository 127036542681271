.search-autocomplete {
  &__menu {
	list-style: none;
	position: absolute;
	background: #FFFFFF;
	box-shadow: 8px 8px 20px rgb(0 0 0 / 20%);
	border-radius: 5px;
	padding: 0;
	margin: 0;
	width: 100%;
	z-index: 999;
	max-width: 240px;
	max-height: 300px;
	overflow-x: auto;

	li {
	  padding: 6px 16px;
	  font-family: 'Roboto', sans-serif;
	  font-weight: 400;
	  font-size: 14px;
	  line-height: 1;
	  color: rgba(0, 0, 0, 0.5);
	  cursor: pointer;
	  transition: all .2s;
	  height: 40px;
	  display: flex;
	  align-items: center;
	  justify-content: left;

	  &:hover,
	  &.active {
		background: #88C559;
		color: #FFFFFF;
	  }
	}
  }
}
