nav {
  width: 100%;
  height: 42px;
  background-image: url("../../../assets/images/header-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.navbar {
  margin-left: 15%;
  margin-right: 15%;
  display: flex;
  align-items: center;
  height: 100%;

  &__logo {
	height: 30px;

	img {
	  height: 30px;
	}
  }


  &__right {
	list-style: none;
	margin: 0 0 0 auto;
	padding: 0;
	height: 42px;
	display: flex;

	li {
	  display: inline-block;
	  height: 42px;

	  .link-item {
		padding-left: 8px;
		padding-right: 8px;
		text-decoration: none;
		color: #ffffff;
		font-size: 14px;
		font-weight: 700;
		height: 100%;
		line-height: 42px;
		display: block;
		transition: all .4s ease;


		&:hover {
		  background-color: #ffffff;
		  color: rgba(0, 0, 0, .3);
		}
	  }
	}
  }

  &__dropdown {
	height: 42px;
	display: block;
	position: relative;

	&--btn {
	  padding-left: 8px;
	  padding-right: 8px;
	  height: 42px;
	  padding-top: 8px;
	  cursor: pointer;
	  transition: all .4s ease;


	  svg {
		width: 26px;
		height: 26px;
		transition: all .4s ease;
	  }

	  &:hover {
		background-color: #ffffff;

		svg path {
		  fill: rgba(0, 0, 0, .3);
		}
	  }
	}

	&--link {
		padding-left: 8px;
		padding-right: 8px;
		text-decoration: none;
		color: #ffffff;
		font-size: 14px;
		font-weight: 700;
		height: 100%;
		line-height: 42px;
		display: block;
		transition: all .4s ease;


		&:hover {
			background-color: #ffffff;
			color: rgba(0, 0, 0, .3);
			cursor: pointer;
		}
	}

	&--menu {
	  position: absolute;
	  box-shadow: 0 50px 100px rgb(50 50 93 / 10%), 0 15px 35px rgb(50 50 93 / 15%), 0 5px 15px rgb(0 0 0 / 10%);
	  top: 100%;
	  left: 0;
	  z-index: 1000;
	  float: left;
	  min-width: 10rem;
	  padding: 0.5rem 0;
	  font-size: 1rem;
	  color: #212529;
	  text-align: left;
	  list-style: none;
	  background-color: #fff;
	  background-clip: padding-box;
	  border: 1px solid rgba(0, 0, 0, 0.15);
	  border-radius: 0.25rem;
	  margin-top: 0;

	  &:focus-visible,
	  &:focus {
		outline: none;
	  }
	}

	&--menu-item {
	  padding: 8px 16px;
	  color: rgba(0, 0, 0, .3);
	  font-size: 14px;
	  cursor: pointer;

	  &:hover {
		background-color: rgba(0, 0, 0, .3);
		color: #ffffff;

		a {
			color: #ffffff;
		}
	  }

	  a {
		text-decoration: none;
		font-size: 14px;
		display: block;
		color: rgba(0, 0, 0, .3);
	  }
	}
  }
}


