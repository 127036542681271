.table {
  border-collapse: collapse;
  width: 100%;

  thead {
	background: #9F9F9F;

	th {
	  color: #FFFFFF;
	  font-family: 'Roboto', sans-serif;
	  font-weight: 500;
	  font-size: 14px;
	  line-height: 16px;
	  text-align: left;
	  padding: 20px 15px;
	  white-space: nowrap;

	  svg {
		position: relative;
		right: -6px;
		top: -4px;
	  }
	}

	th:first-child {
	  border-top-left-radius: 5px;
	  border-bottom-left-radius: 5px;
	}

	th:last-child {
	  border-top-right-radius: 5px;
	  border-bottom-right-radius: 5px;
	}
  }

  tbody {
	tr {
	  td {
		padding: 5px 15px;
		font-family: 'Raleway', sans-serif;
		font-weight: 600;
		font-size: 12px;
		line-height: 14px;
		color: rgba(0, 0, 0, 0.5);
		white-space: nowrap;
		border-bottom: 1px solid rgba(217, 217, 217, 0.2);
		vertical-align: middle;

		img {
		  width: 45px;
		  height: 45px;
		  border: 1px solid rgba(0, 0, 0, 0.1);
		  border-radius: 5px;
		}

		&.no-data {
		  padding: 15px;
		  font-size: 14px;
		}

		.edit-btn, .delete-btn {
			cursor: pointer;
		}
	  }
	}
	.expanded-row {
		.codes-link {
			margin: 2px;
			color: #88C559;
			text-decoration: none;

			&:hover {
				cursor: pointer;
			}
		}
	}
  }
}

.with-scroll {
  max-height: 288px;
  overflow-y: auto;
}

.no-image-table {
  tbody {
	tr {
	  td {
		padding: 15px;
	  }
	}
  }
}

.white-space-normal {
  thead {
	th {
	  white-space: normal;
	}
  }
}

// Custom styles
.activity {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);

  &.active {
	background-color: #88C559;
  }

  &.inactive {
	background-color: #FF5F6D;
  }

  &.draft {
	background-color: #FFA400;
  }
}

