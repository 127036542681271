.expand-row {
  width: 20px;
  height: 10px;

  &:hover {
    cursor: pointer;
  }

  path {
    stroke: #9F9F9F;
    &:hover {
      stroke: #88C559;
    }
  }
}