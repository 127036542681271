.notification {
  display: flex;
  position: fixed;
  top: 20px;
  right: 60px;
  align-items: center;
  border-radius: 5px;
  padding: 26px 36px;
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.2);
  z-index: 99;
  max-width: 400px;

  &--message {
    width: 97%;
    text-align: center;

    p {
      margin: 0;
      font-size: 14px;
      color: #FFFFFF;
      font-weight: 600;
      font-family: "Raleway", sans-serif;
    }
  }

  &.success {
    border: 1px solid #32be3f;
    background-color: #32be3f;
  }

  &.error {
    border: 1px solid #FF5F6D;
    background-color: #FF5F6D;
  }

  &.warning {
    border: 1px solid #e59c1e;
    background-color: #e59c1e;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-left: 20px;

    path {
      fill: #FFFFFF;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
