.in-campaign {
  padding-top: 40px;

  &__header {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: flex-start;

    button {
      margin-left: 14px;
    }
  }

  &__body {
    min-height: 288px;

    a {
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #88C559;
      display: block;
      margin-top: 20px;
      text-decoration: none;
    }
  }
}
