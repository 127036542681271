.prizes {
  padding-top: 42px;
  padding-bottom: 100px;

  &__header {
	width: 100%;
	height: 56px;
	background: #F5F5F5;
	border-radius: 5px;
	padding: 8px 32px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
  }
}

.import-prizes {
  padding-top: 20px;
  padding-bottom: 50px;
  text-align: center;
}
