.double-input-wrapper {
  display: flex;
  align-items: center;
  border-radius: 5px;

  &.focused {
	outline: 1px solid #88c559;
  }

  input {
	background: #FFFFFF;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	height: 40px;
	font-size: 14px;
	line-height: 1;
	color: rgba(0, 0, 0, 0.5);
	padding: 12px 22px;
	font-family: "Roboto", sans-serif;
	width: 100%;
	-moz-appearance: textfield;
	text-align: center;

	&::placeholder {
	  font-family: 'Roboto', sans-serif;
	  font-size: 14px;
	  line-height: 14px;
	  color: rgba(55, 63, 65, 0.3);
	}

	&:focus,
	&:focus-visible {
	  outline: none;
	}

	/* Chrome, Safari, Edge, Opera */
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
	  -webkit-appearance: none;
	  margin: 0;
	}

	&:first-child {
	  width: 47%;
	  border-right: none;
	  border-bottom-right-radius: 0;
	  border-top-right-radius: 0;
	  padding: 0 4px;
	}

	&:last-child {
	  width: 47%;
	  border-left: none;
	  border-bottom-left-radius: 0;
	  border-top-left-radius: 0;
	  padding: 0 4px;
	}
  }

  span {
	color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	height: 40px;
	width: 6%;
  }
}

