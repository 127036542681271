.file-upload {
  width: 53%;
  display: flex;
  justify-content: space-between;

  input[type="file"] {
	display: none;
  }

  input[type="text"] {
	width: 415px;
  }

  label {
	padding: 15px 22px 0;
	height: 40px;
	border-radius: 5px;
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	font-size: 12px;
	line-height: 1;
	text-transform: uppercase;
	box-sizing: border-box;
	box-shadow: none;
	border-style: none;
	cursor: pointer;
	background-color: #88C559;
	color: #FFFFFF;
	border-color: #88c559;
  }
}
