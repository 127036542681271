.pools {
  padding-top: 42px;
  padding-bottom: 100px;

  &__header {
    width: 100%;
    height: 56px;
    background: #F5F5F5;
    border-radius: 5px;
    padding: 8px 32px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.copy-pool__form > .form-control {
  display: flex;
  align-items: center;

  .form-control__label span {
    margin: 0;
  }
}

.modal__footer--copy {
  border-top: none;
}

