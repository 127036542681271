.prize-details {
  padding-top: 60px;
  display: flex;

  &__left {
    width: 43%;
    margin-right: 1%;
    display: flex;
    justify-content: space-around;
  }

  &__right {
    width: 55%;

    &--input {
      margin-bottom: 20px;

      &:first-child {
        display: flex;

        .input-group:first-child {
          width: 138px;
          margin-right: 20px;
        }

        .input-group:last-child {
          width: 100%;
          margin-right: 20px;
        }
      }
    }

    &--input:last-child {
      display: flex;
      align-items: center;
      justify-content: right;

      input {
        width: 50px;
        margin-right: 20px;
        margin-left: 20px;
      }

      span {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        line-height: 1.6;
        color: rgba(0, 0, 0, 0.5)
      }
    }
  }
}
