.common-code-batch {
    margin-top: 2rem;
}

.common-code-batch__row {
    display: flex;
    margin-bottom: 1.5rem;
}

.common-code-batch__form-control {
    display: flex;
    align-items: center;
}

.common-code-batch__form-control--barcode {
    flex-grow: 1;
}

.form-control__label {
    color: #9F9F9F;
    margin: 0 1.5rem;
}

.common-code-batch__form-control--codes-amount label {
    min-width: 6.875rem;
    text-align: center;
}

.common-code-batch__form-control--expiration {
    label {
        min-width: 6.875rem;
        margin-left: 0;
    }

    svg {
        margin-right: 10px;
    }

    .react-datepicker-wrapper {
        width: auto;

        input {
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            height: 40px;
            font-size: 14px;
            line-height: 1;
            color: rgba(0, 0, 0, 0.5);
            padding: 12px 15px;
            font-family: "Roboto", sans-serif;
            width: 120px;

            &:focus,
            &:focus-visible {
                outline: 1px solid #88c559;
            }
        }
    }

    .react-datepicker__day {
        &--keyboard-selected {
            background-color: #88c559;
        }

        &--selected {
            background-color: #88c559;
        }
    }
}

.common-code-batch__form-control--validity {
    margin-left: 13rem;

    label {
        min-width: 6.875rem;
        text-align: right;
    }

    input {
        width: 6.125rem;
    }
}