.tabs {
  &__list {
	display: flex;
	justify-content: left;
  }
  &__btn {
	padding: 6px 20px;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	line-height: 1.6;
	text-align: center;
	color: rgba(0, 0, 0, 0.15);
	border: none;
	cursor: pointer;
	background-color: transparent;

	&.active {
	  color: #9F9F9F;
	  outline: none;
	  border-bottom: 2px solid #88C559;
	}

	&:disabled {
	  cursor: not-allowed;
	}
  }

  &.big {
	.tabs__btn {
	  font-size: 20px;
	}
  }

  &.small {
	.tabs__btn {
	  font-size: 16px;
	}
  }
}


