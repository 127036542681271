.alert {
  position: relative;
  z-index: 50;

  &__backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, .8);
  }

  &__container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 16px;
  }

  &__panel {
	width: 400px;
	background: #FFFFFF;
	border-radius: 5px;
	padding: 20px 30px 0;

	h2 {
	  margin: 0;
	  padding-bottom: 20px;
	  font-family: 'Roboto', sans-serif;
	  font-weight: 400;
	  font-size: 16px;
	  line-height: 1.6;
	  text-transform: uppercase;
	  color: #9F9F9F;
	  position: relative;
	}

	svg {
	  width: 16px;
	  height: 16px;
	  &.primary {
		path {
		  fill: #88C559;
		}
	  }

	  &.danger {
		path {
		  fill: #FF5F6D;
		}
	  }
	}
  }

  &__close {
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
  }

  &__description {
	padding-top: 20px;
	padding-bottom: 20px;
	text-align: center;
	color: #9F9F9F;
  }

  &__footer {
	display: flex;
	padding-top: 30px;
	justify-content: center;
  }
}
