.dropdown {
  position: relative;


  &.multi {
	width: 240px;

	&__btn {
	  max-width: 240px;
	}
  }


  &__btn {
	height: 40px;
	background: #FFFFFF;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: rgba(0, 0, 0, 0.5);
	padding: 12px 44px 12px 16px;
	position: relative;
    cursor: pointer;
	width: 100%;
	text-align: left;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	svg {
	  position: absolute;
	  right: 16px;
	  top: 16px;
	}
  }

  &__menu {
	list-style: none;
	position: absolute;
	background: #FFFFFF;
	box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	padding: 0;
	margin: 0;
	width: 100%;
	z-index: 999;

	&--item {
	  padding: 6px 16px;
	  font-family: 'Roboto', sans-serif;
	  font-weight: 400;
	  font-size: 14px;
	  line-height: 1;
	  color: rgba(0, 0, 0, 0.5);
	  cursor: pointer;
	  transition: all .2s;
	  height: 40px;
	  display: flex;
	  align-items: center;
	  justify-content: left;

	  &:hover,
	  &.active {
		background: #88C559;
		color: #FFFFFF;
	  }

	  &.disabled {
		  color: rgba(0, 0, 0, 0.2);
		  &:hover,
		  &.active {
			  background: none;
			  color: rgba(0, 0, 0, 0.2);
		  }
	  }
	}
  }
}
