.codes-container {
  .search-header {
    display: flex;
    margin-bottom: 13px;
    width: 480px;
    justify-content: space-between;
  }

  .codes-list {
    min-height: 518px;

    &__header {
      background: #9F9F9F;
      margin-bottom: 4px;
      border-radius: 5px;

      th {
        color: #FFFFFF;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        padding: 20px 22px;
        white-space: nowrap;
      }
    }

    &__body {
      max-height: 457px;
      overflow: auto;
      margin-bottom: 30px;

      &::-webkit-scrollbar-thumb {
        background-color: #88C559; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        border: 3px solid #88C559; /* creates padding around scroll thumb */
      }

      &::-webkit-scrollbar-track {
        background: rgba(136, 197, 89, 0.2);
      }

      &::-webkit-scrollbar {
        width: 6px; /* width of the entire scrollbar */
      }
    }

    &__row {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      border-bottom: 1px solid #D9D9D9;

      &:hover > div {
        opacity: 1;
        color: #88C559;
      }
    }

    &__link {
      font-size: 14px;
      opacity: 0;
      text-transform: uppercase;
      cursor: pointer;
    }

    &__name {
      font-size: 14px;
      color: #000000;
      opacity: 0.5;
      cursor: default;
    }
  }
}


